import { IAutomation } from "./types";

const specialBankInstitutions = new Map<string, string>([
  ["ins_115617", "savings"],
]);

export const getAccountSubType = (subType: string, institutionId: string) => {
  return specialBankInstitutions.get(institutionId) || subType;
};

export function findAccountIdByAutomation(
  automation: IAutomation | undefined,
  checkingAccountId: string,
  savingsAccountId: string,
) {
  return automation?.linkedAccountsIds?.find((accountId) => {
    return accountId === checkingAccountId || accountId === savingsAccountId;
  });
}

import "./DeleteAccountModal.css";
import Button from "../Button/Button";
import diconnect_icon from "../../assets/disconnect_icon.svg";

type DeleteAccountModalProp = {
  onHide: () => void;
  deleteAction: any;
  title: string;
  description: string;
  type: string;
};

function DeleteAccountModal({
  onHide,
  deleteAction,
  title,
  description,
  type,
}: DeleteAccountModalProp) {
  const deleteHandler = () => {
    deleteAction();
    onHide();
  };

  const isDisconnected = title === "This account cannot be disconnected";

  return (
    <div className="containerDeleteAccountModal">
      <div className="modal__content">
        <div className="modal__content-iconHolder">
          <img src={diconnect_icon} alt="Diconnect Icon" />
        </div>
        <div className="deleteAccount__title" >{title}</div>
        <div className="deleteAccount__description">{description}</div>
        {type === "disconnect" && (
          <div className="groupButtonModal">
            <Button
              onClick={onHide}
              type={"white-btn"}
              style={{
                color: "var(--primary-color)",
                backgroundColor: "var( --secondary-black-color)",
                border: "1px solid var(--primary-color)",
                flex: "1",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteHandler}
              type={"black-btn deleteBtn"}
              style={{
                color: "var(--grey30))",
                backgroundColor: "var(--error)",
                flex: "1",
              }}
            >
              Disconnect Account
            </Button>
          </div>
        )}

        {type === "disableDisconnect" && (
          <div className="groupButtonModal" data-test="go-to-automations-button">
            <Button
              onClick={onHide}
              type={"white-btn"}
              style={{
                color: "var(--gray40)",
                backgroundColor: "var(--primary-color)",
                border: "1px solid var(--primary-color)",
                minWidth: '227px',
              }}
            >
              Go To Automations
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteAccountModal;

import { AccountType } from "../../helpers/types";
import AccountSettings from "./AccountSettings";

interface ConnectedAccountsListProps {
  checkingAccounts: any;
  savingsAccounts: any;
  reloadPage: () => void;
  disconnectAccountHandler: (account: any) => void;
}

const ConnectedAccounts = ({
  checkingAccounts,
  savingsAccounts,
  reloadPage,
  disconnectAccountHandler,
}: ConnectedAccountsListProps) => {
  const checkingAccountsList = checkingAccounts.map(
    (account: any, index: number) => {
      if (account.cacheType === AccountType.SAVINGS) {
        return;
      }
      return (
        <AccountSettings
          key={index}
          account={account}
          relationType={AccountType.CHECKING}
          reloadPage={reloadPage}
          disconnectAccountHandler={disconnectAccountHandler}
        />
      );
    },
  );

  const savingsAccountList = [...checkingAccounts, ...savingsAccounts].map(
    (account: any, index: number) => {
      if (account.cacheType === AccountType.CHECKING) {
        return;
      }
      return (
        <AccountSettings
          key={index}
          account={account}
          relationType={AccountType.SAVINGS}
          reloadPage={reloadPage}
          disconnectAccountHandler={disconnectAccountHandler}
        />
      );
    },
  );

  return (
    <div>
      <div className="block_accounts" id="connected-accounts">
        <div className="settings__title" data-test="connected-accounts-title">Connected Accounts</div>
        <div className="profile__blockInfo">
          <div className="profile__title" data-test="checking-accounts-title">Checking Accounts</div>
          {checkingAccountsList}
        </div>
        <div className="profile__blockInfo">
          <div className="profile__title" data-test="savings-accounts-title">Savings Accounts</div>
          {savingsAccountList}
        </div>
      </div>
    </div>
  );
};

export default ConnectedAccounts;

import AccountCard from "../AccountCard/AccountCard";
import tooltip_info_icon from "../../assets/tooltip_info.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetDisabledAutomation } from "../../redux/sliceUserAutomation";
import { getTransferDirectionInfo } from "../../helpers/transfersUtils";
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import Button from "../Button/Button";
import notification from "../ToastNotifications/ToastNotifications";
import { setReconnectError } from "../../redux/sliceNotifications";
// @ts-ignore
import arrows from "../../assets/arrows_vertical.png";
import arrows_red from "../../assets/arrows_red_vertical.png";
import { Tooltip } from "@mui/material";
import { useMixpanel } from "../../helpers/mixpanel";
import { AutomationStatuses } from "../../helpers/enumStatuses";
import { IAutomation, IInitiatedTransactionMap } from "../../helpers/types";

interface ICardsBlockProps {
  automation: IAutomation;
  initiatedTransactionsMap?: IInitiatedTransactionMap;
  isUserSuspicion: boolean;
}

const CardsBlock = ({
  automation,
  initiatedTransactionsMap = {},
  isUserSuspicion,
}: ICardsBlockProps) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  let initiatedTransaction =
    initiatedTransactionsMap?.[automation.checkingAccountId] ||
    initiatedTransactionsMap?.[automation.savingAccountId];

  const isExistTransactionInitiated =
    initiatedTransaction?.showMessage ?? false;

  const transactionAmountForAutomation =
    initiatedTransaction?.amount.toFixed(2);

  const editAutomationHandler = () => {
    // dispatch(setSelectedAutomation(automation));
    navigation(`/automation-edit/${automation.id}`);
  };

  const { status, checkingAccount, savingAccount, fixedValue } = automation;

  const shouldShowAutomationPausedMsg =
    status === AutomationStatuses.RECONNECTION_NEED;

  const isActiveAutomation =
    !isExistTransactionInitiated &&
    !shouldShowAutomationPausedMsg &&
    (automation.status === AutomationStatuses.ENABLED || isUserSuspicion);

  const { to } = getTransferDirectionInfo(initiatedTransaction?.direction);

  const accessToken = checkingAccount.accessToken;

  const reloadPage = () => {
    notification({
      message: "Reconnection successful. Automation resumed.",
      type: "success",
    });
    dispatch(resetDisabledAutomation());
    dispatch(setReconnectError(false));
    navigation("/automations");
  };

  return (
    <div className="automation__container">
      <div className="automations__cardsDetails">
        <button className="profile__edit_save" onClick={editAutomationHandler} data-test="profile-edit-save-button">
          Edit
        </button>
      </div>
      <div className="connectionButton__holder" data-test="connection-button-holder">
        <div className="automations__checkingCards">
          <AccountCard
            active={true}
            item={checkingAccount}
            type="Checking account"
            reconnectionFlow={true}
            showOnlyDisconnectedIcon={true}
            disconnected={
              checkingAccount.status === AutomationStatuses.RECONNECTION_NEED
                ? true
                : false
            }
            selectHandler={() => {}}
            editFlow={false}
          />
          <div className="automation__fixedValue">
            <p className="automation__fixedValue-title">Fixed Balance</p>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            }).format(+fixedValue)}
          </div>
        </div>

        <div className="arrows__icon">
          {[checkingAccount.status, savingAccount.status].includes(
            AutomationStatuses.RECONNECTION_NEED,
          ) ? (
            <img src={arrows_red} alt="Arrows Icon" />
          ) : (
            <img src={arrows} alt="Arrows Icon" />
          )}
        </div>

        <div className="automations__savingsCards">
          <AccountCard
            active={true}
            item={savingAccount}
            type="Savings account"
            reconnectionFlow={true}
            showOnlyDisconnectedIcon={true}
            disconnected={
              savingAccount.status === AutomationStatuses.RECONNECTION_NEED
                ? true
                : false
            }
            selectHandler={() => {}}
            editFlow={false}
          />
        </div>
      </div>
      {isActiveAutomation && (
        <div className="transfer__status">
          <p className="transfer__statusTitle ">
            <span>Automation is Active.</span> How does it work?
          </p>
          <div>
            <Tooltip
              title={
                <>
                  The minimal transfer is $250. If your checking balance{" "}
                  <span style={{ fontWeight: "700" }}>exceeds</span> the fixed
                  balance by $250, we'll move any excess to savings. If it{" "}
                  <span style={{ fontWeight: "700" }}>dips below</span> your
                  fixed balance by $250, we'll top it up using your savings.
                </>
              }
              arrow
              enterTouchDelay={0}
              componentsProps={{
                tooltip: {
                  sx: {
                    textAlign: "left",
                    width: "288px",
                  },
                },
              }}
            >
              <img src={tooltip_info_icon} alt="Info Icon" />
            </Tooltip>
          </div>
        </div>
      )}
      {isExistTransactionInitiated && !shouldShowAutomationPausedMsg && (
        <p className="transfer__statusTitle-pending text__content">
          {`We initiated a transfer of $${transactionAmountForAutomation} to ${to} account.`}
        </p>
      )}
      {isExistTransactionInitiated &&
        initiatedTransaction?.existsOldTransaction &&
        !shouldShowAutomationPausedMsg && (
          <p className="transfer__statusTitle-pending text__content">
            {`Your updated automation will take effect once this transfer is complete.`}
          </p>
        )}

      {shouldShowAutomationPausedMsg && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            className="transfer__statusTitle-paused text__content"
            style={{ marginTop: "24px" }}
          >
            Automation paused due to disconnected bank accounts.
          </p>

          <PlaidAuth accessToken={accessToken} reloadPage={reloadPage}>
            <Button
              type={"white-btn"}
              onClick={() => {
                mixpanel("Clicked_Reconnect_Now", {
                  "Origination point": "automation",
                });
              }}
              style={{
                color: "var(--error)",
                backgroundColor: "var(--secondary-black-color)",
                border: "1px solid var(--error)",
                marginTop: "24px",
                width: "195px",
              }}
            >
              Reconnect Now
            </Button>
          </PlaidAuth>
        </div>
      )}
    </div>
  );
};

export default CardsBlock;

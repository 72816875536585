import { IAutomation } from "./types";

export function filterAutomationsByAccountIds(
  automations: IAutomation[],
  checkingAccountId: string,
  savingsAccountId: string,
) {
  return automations.filter((automation: IAutomation) => {
    const existsChecking =
      automation.linkedAccountsIds?.includes(checkingAccountId);
    const existsSavings =
      automation.linkedAccountsIds?.includes(savingsAccountId);
    return existsChecking || existsSavings;
  });
}

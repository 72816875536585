import { TextField } from "@mui/material";

interface CurrencyInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  id: string;
  className: string;
  error: boolean;
  inputMode?: string;
  autoComplete?: string;
  inputProps?: {};
}

const formatCurrency = (value: string | number): string => {
  const numericValue =
    typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : value;
  if (Number.isNaN(numericValue)) return "";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(numericValue);
};

const parseCurrency = (value: string): string => {
  // Remove non-numeric characters except dot
  return value.replace(/[^0-9.]/g, "");
};

const CustomInput = (props: any) => <TextField {...props} />;

const isAllowed = (rawValue: string): boolean => {
  const numericValue = parseFloat(rawValue.replace(/,/g, ""));
  return (numericValue > 0 && numericValue <= 999999) || rawValue === "";
};

const CurrencyInput = ({
  value,
  onChange,
  placeholder = "Enter sum in $",
  id = "currency-input",
  className = "",
  error = false,
  inputProps,
}: CurrencyInputProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = parseCurrency(e.target.value);
    if (isAllowed(rawValue)) {
      onChange(rawValue);
    }
  };

  return (
    <CustomInput
      id={id}
      value={value ? formatCurrency(value) : ""}
      onChange={handleInputChange}
      className={`${className} ${error ? "error" : ""}`}
      placeholder={placeholder}
      inputMode="decimal"
      autoComplete="off"
      inputProps={inputProps}
    />
  );
};

export default CurrencyInput;

import { Box, Button, Container, Grid } from "@mui/material";
import instagramIcon from "../assets/instagramIcon.svg";
import facebookIcon from "../assets/facebookIcon.svg";
import twitterIcon from "../assets/twitterIcon.svg";
import linkedinIcon from "../assets/linkedinIcon.svg";
import "./footer.css";

import logo from "../assets/cache_logo.svg";
import { Link } from "react-router-dom";
import { useMixpanelPublic } from "../helpers/mixpanel";

declare global {
  interface Window {
    Tawk_API: any;
  }
}

function Footer() {
  const mixpanelPublic = useMixpanelPublic();
  const handleClickContactUs = () => {
    window.Tawk_API = window.Tawk_API || {};
    if (typeof window.Tawk_API?.toggle !== undefined) {
      window.Tawk_API.toggle();
    }
  };
  return (
    <footer>
      <hr className="divider" />
      <Box className="blackBoxFooter" textAlign="center">
        <Container>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <img src={logo} alt="Logo" className="landing_logo" />
            </Grid>
            <Grid item xs={12}>
              <Box className="links">
                <Box className="links__list">
                  <Link className="links__list__item" to="/faq" data-test="link-faq">
                    FAQs
                  </Link>
                  <Link className="links__list__item" to="/blog" data-test="link-blog">
                    Blog
                  </Link>
                  <Link
                    className="links__list__item"
                    to="#"
                    onClick={handleClickContactUs}
                    data-test="link-contact-us"
                  >
                    Contact us
                  </Link>
                  <Link className="links__list__item" to="/terms-of-service" data-test="link-terms-of-service">
                    Terms of Service
                  </Link>
                  <Link className="links__list__item" to="/privacy-policy" data-test="link-privacy-policy">
                    Privacy Policy
                  </Link>
                </Box>
                <div className="iconsContainer">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/mycacheai"
                    onClick={() => {
                      mixpanelPublic("Social_Event_Clicked", {
                        platform: "instagram",
                      });
                    }}
                  >
                    <img src={instagramIcon} alt="instagram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/mycacheai"
                    onClick={() => {
                      mixpanelPublic("Social_Event_Clicked", {
                        platform: "facebook",
                      });
                    }}
                  >
                    <img src={facebookIcon} alt="facebook" />
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/mycacheai"
                    onClick={() => {
                      mixpanelPublic("Social_Event_Clicked", {
                        platform: "twitter",
                      });
                    }}
                  >
                    <img src={twitterIcon} alt="twitter" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/mycacheai/"
                    onClick={() => {
                      mixpanelPublic("Social_Event_Clicked", {
                        platform: "linkedin",
                      });
                    }}
                  >
                    <img src={linkedinIcon} alt="linkedin" />
                  </a>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} className="copyright">
              © {new Date().getFullYear()} Cache Inc. All rights reserved.
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
}

export default Footer;

import { Box, Container, Typography } from "@mui/material";
import { useMixpanelPublic } from "../../helpers/mixpanel";
import { Link } from "react-router-dom";
import "./faqSection.css";

const FaqSection = () => {
  const mixpanel = useMixpanelPublic();
  const handleClick = () => {
    mixpanel("Clicked_FAQs");
  };
  const title = "Want more information?";
  const subttile =
    "Before joining Cache, browse through our frequently asked questions to find the answers you're looking for.";
  return (
    <Container>
      <Typography className="faqTitle" data-test="want-more-info-title">
        {title}
      </Typography>
      <Box className="faqBoxSubtitle">
        <Typography className="faqSubtitle" data-test="want-more-info-subtitle">
          {subttile}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Link to="/faq" onClick={handleClick}>
          <Box className="faq_button">Read FAQs</Box>
        </Link>
      </Box>
    </Container>
  );
};

export default FaqSection;

import { ChangeEvent, useState } from "react";
import Button from "../Button/Button";
import trash_icon from "../../assets/trash.png";
import "./DeleteQuestionsModal.css";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

type DeleteAutomationModalProp = {
  onHide: () => void;
  deleteAction: any;
  type: string;
};

function DeleteQuestionsModal({
  onHide,
  deleteAction,
  type,
}: DeleteAutomationModalProp) {
  const [wantDeleteAutomation, setWantDeleteAutomation] = useState(false);
  const [wantDeleteAccount, setWantDeleteAccount] = useState(false);
  const [userOwnAnswer, setUserOwnAnswer] = useState(false);
  const [answers, setAnswers] = useState<string[]>([]);
  const [ownAnswerText, setOwnAnswerText] = useState<string>("");

  const selectHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setAnswers((prev) => [...prev, value]);
    } else {
      setAnswers((prev) => {
        return [...prev.filter((answer) => answer !== value)];
      });
    }
  };

  const userTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setOwnAnswerText(value);
  };

  const automationQuestions = [
    "I just want to create a new automation.",
    "I don’t understand how automations work.",
    "I prefer to manage my checking and savings manually.",
    "I don’t earn much interest.",
    "Something else.",
  ];

  const automationQuestionsList = (
    <>
      <div className="modal__content-iconHolder">
        <img src={trash_icon} alt="trash icon" />
      </div>
      <div className="deleteAutomation__title">Delete Automation.</div>
      <div className="deleteAutomation__description">
        Please help us improve by selecting the reason(s) you want to delete
        this automation.
      </div>
      <div className="deleteAutomation__questionsContainer">
        {automationQuestions.map((item) => (
          <CustomCheckbox label={item} onChange={selectHandler} />
        ))}
      </div>
      <div className="modalButtonHolder">
        <Button
          onClick={onHide}
          type={"white-btn"}
          style={{
            color: "var(--primary-color)",
            backgroundColor: "var(--secondary-black-color)",
            border: "1px solid var(--primary-color)",
            flex: 1,
          }}
        >
          I Changed My Mind
        </Button>

        <Button
          onClick={() => setWantDeleteAutomation(true)}
          disabled={answers.length === 0}
          type={"black-btn deleteBtn"}
          style={{
            color: "var(--grey30))",
            backgroundColor: "var( --primary-color)",
            flex: 1,
          }}
          dataTest="delete-automation-continue-button"
        >
          Continue
        </Button>
      </div>
    </>
  );

  const userAccountQuestions = [
    "I don’t understand how Cache works.",
    "I prefer to manage my checking and savings manually.",
    "I use a different tool for saving.",
    "I don’t earn much interest.",
    "Something else.",
  ];

  const accountQuestionsList = (
    <>
      <div className="modal__content-iconHolder">
        <img src={trash_icon} alt="trash icon" />
      </div>
      <div className="deleteAutomation__title" data-test="delete-account-title">Delete Cache account.</div>
      <div className="deleteAutomation__description">
        Please help us improve by selecting the reason(s) you want to delete
        your account.
      </div>
      <div className="deleteAutomation__questionsContainer" data-test="delete-account-questions-container">
        {userAccountQuestions.map((item) => (
          <CustomCheckbox label={item} onChange={selectHandler} />
        ))}
      </div>
      <div className="modalButtonHolder" data-test="change-my-mind-button">
        <Button
          onClick={onHide}
          type={"white-btn"}
          style={{
            color: "var(--primary-color)",
            backgroundColor: "var(--secondary-black-color)",
            border: "1px solid var(--primary-color)",
            flex: 1,
          }}
        >
          I Changed My Mind
        </Button>

        <Button
          onClick={() => setWantDeleteAccount(true)}
          disabled={answers.length === 0}
          type={"black-btn deleteBtn"}
          style={{
            color: "var(--grey30))",
            backgroundColor: "var( --primary-color)",
            flex: 1,
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );

  const automationDeleteСonfirmation = (
    <>
      <div className="modal__content-iconHolder">
        <img src={trash_icon} alt="trash icon" />
      </div>
      <div className="deleteAutomation__title">
        Are you sure you want to delete this automation?
      </div>
      <div className="deleteAutomation__description fixed">
        This cannot be undone. To save, you’ll need to set up your automation
        again.
      </div>
      <div className="modalButtonHolder">
        <Button
          onClick={onHide}
          type={"white-btn"}
          style={{
            color: "var(--primary-color)",
            backgroundColor: "var(--secondary-black-color)",
            border: "1px solid var(--primary-color)",
            flex: 1,
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            deleteAction(answers);
            onHide();
          }}
          type={"black-btn deleteBtn"}
          style={{
            color: "var(--grey30))",
            backgroundColor: "var(--error)",
            flex: 1,
          }}
          data-test="delete-automation-button"
        >
          Delete Automation
        </Button>
      </div>
    </>
  );

  const accountOwnAnswer = (
    <>
      <div className="deleteAutomation__title" style={{ marginTop: "0" }}>
        Please tell us more about the reason(s) you selected.
      </div>
      <div className="deleteAutomation__description">
        This helps us improve Cache for users like you.
      </div>
      <textarea
        className="delete__textarea"
        placeholder="Please elaborate (optional)"
        onChange={userTextHandler}
      />
      <div className="modalButtonHolder">
        <Button
          onClick={() => {
            setUserOwnAnswer(true);
          }}
          type={"black-btn deleteBtn"}
          style={{
            color: "var(--grey30)",
            backgroundColor: "var(--primary-color)",
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );

  const accountDeleteСonfirmation = (
    <>
      <div className="modal__content-iconHolder">
        <img src={trash_icon} alt="trash icon" />
      </div>
      <div className="deleteAutomation__title">
        Are you sure you want to delete your account?
      </div>
      <div className="deleteAutomation__description fixed">
        This cannot be undone. To save again with Cache, you’ll need to create a
        new account.
      </div>
      <div className="modalButtonHolder">
        <Button
          onClick={onHide}
          type={"white-btn"}
          style={{
            color: "var(--primary-color)",
            backgroundColor: "var(--secondary-black-color)",
            border: "1px solid var(--primary-color)",
            flex: 1,
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            deleteAction(answers, ownAnswerText);
            onHide();
          }}
          type={"black-btn deleteBtn"}
          style={{
            color: "var(--grey30))",
            backgroundColor: "var(--error)",
            flex: 1,
          }}
        >
          Delete Account
        </Button>
      </div>
    </>
  );

  return (
    <div className="containerDeleteAutomationModal">
      <div className="modal__content">
        {!wantDeleteAutomation &&
          type === "automation" &&
          automationQuestionsList}
        {wantDeleteAutomation &&
          type === "automation" &&
          automationDeleteСonfirmation}
        {!wantDeleteAccount && type === "account" && accountQuestionsList}
        {wantDeleteAccount &&
          type === "account" &&
          !userOwnAnswer &&
          accountOwnAnswer}
        {wantDeleteAccount &&
          type === "account" &&
          userOwnAnswer &&
          accountDeleteСonfirmation}
      </div>
    </div>
  );
}

export default DeleteQuestionsModal;
